import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";

const DetailedSkillsPage = () => {
  const { pathname } = useLocation();
  return (
    <div>
      <div>bob</div>
    </div>
  );
};

export default DetailedSkillsPage;
